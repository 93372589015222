// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Resolvers
import { blockCardResolver } from 'containers/card-settings/views/block-card/resolvers/card-settings-block-card.resolvers';
// Types
import { CardSettingsBlockCardSecondStepActionsReturnHandlersType } from './types/card-settings-block-card-second-step-actions-return.handlers.type';
import { CardSettingsBlockCardSecondStepActionsHandlersType } from './types/card-settings-block-card-second-step-actions.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';
// Utils
import { isReplacementError } from './utils/card-settings-block-card-second-step-actions.handlers.utils';
import { isTemporalAddress } from 'utils/addresses/addresses.utils';

const nextButtonClickHandler = async ({
  address,
  blockReason,
  cardId,
  challenge,
  handleToggleChallengeError,
  selectedAddress,
  setBlockReason,
  setCard,
  setResult,
  setSelectedAddress,
  setShowReplacementModal,
  setTempAddress,
  tempAddress,
}: NextButtonClickHandlerType) => {
  const [response, error] = await blockCardResolver({
    cardId,
    progressId: challenge?.progressId,
    params: {
      address: isTemporalAddress(selectedAddress) && tempAddress ? tempAddress : address,
      reasonCode: blockReason,
      isNewCardRequested: true,
    },
  });

  if (error) {
    if (error === HttpStatusCodesEnum.FORBIDDEN) {
      handleToggleChallengeError();
    } else {
      isReplacementError(response) && setShowReplacementModal(true);
      setResult(false);
      setBlockReason(null);
      setSelectedAddress(AddressTypesEnum.HBTL);
      setTempAddress(null);
    }
  } else {
    setCard(true);
    setResult(true);
  }
};

const CardSettingsBlockCardSecondStepActions = (
  props: CardSettingsBlockCardSecondStepActionsHandlersType
): CardSettingsBlockCardSecondStepActionsReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});

export default CardSettingsBlockCardSecondStepActions;
