// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import CardSettingsBlockCardFirstStepActionsHook from './hooks/card-settings-block-card-first-step-actions.hook';

const CardSettingsBlockCardFirstStepActionsComponent = (): React.ReactElement => {
  const { blockReason } = CardSettingsBlockCardFirstStepActionsHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: !blockReason,
      }}
    />
  );
};

export default CardSettingsBlockCardFirstStepActionsComponent;
