// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Handlers
import CardSettingsBlockCardReplacementModalHandlers from '../handlers/replacement-modal/card-settings-block-card-replacement-modal.handlers';
import CardSettingsBlockCardReplacementModalTrackingHandlers from '../handlers/replacement-modal/card-settings-block-card-replacement-modal.handlers.tracking';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsBlockCardReturnHookType } from './types/card-settings-block-card.hook.type';

const CardSettingsBlockCardHook = (): CardSettingsBlockCardReturnHookType => {
  const navigate = useNavigate(),
    { formatMessage } = useCbIntl();

  const { error, setShowReplacementModal, showReplacementModal } = BlockCardContextConsumerHook();

  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();

  return {
    error,
    formatMessage,
    showReplacementModal,
    replacementModalActions: {
      ...CardSettingsBlockCardReplacementModalHandlers({
        ...AppTrackingHook(CardSettingsBlockCardReplacementModalTrackingHandlers),
        navigate,
        onCancelHybridFlow,
        setShowReplacementModal,
      }),
    },
  };
};

export default CardSettingsBlockCardHook;
