// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Handlers
import CardSettingsBlockCardSecondStepHandlers from '../handlers/card-settings-block-card-second-step.handlers';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
// Types
import { ChallengeType } from 'types/challenge.type';
import { CardSettingsBlockCardSecondStepReturnHookType } from './types/card-settings-block-card-second-step-return.hook.type';
// Utils
import { isTemporalAddress } from 'utils/addresses/addresses.utils';

const CardSettingsBlockCardSecondStepHook = (): CardSettingsBlockCardSecondStepReturnHookType => {
  const [challenge, setChallenge] = useState<ChallengeType>();
  const [challengeError, setChallengeError] = useState(false);

  const { blockReason, selectedAddress, setError, tempAddress } = BlockCardContextConsumerHook();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId, pan: cardNumber, productTypeName } = currentCard || {};
  const { address } = useAddressHook();

  return {
    ...CardSettingsBlockCardSecondStepHandlers({
      challenge,
      setChallenge,
      setChallengeError,
      setError,
    }),
    address: isTemporalAddress(selectedAddress) && tempAddress ? tempAddress : address,
    cardId,
    cardNumber,
    challengeError,
    params: { reasonCode: blockReason, isNewCardRequested: true },
    productTypeName,
    challenge,
  };
};

export default CardSettingsBlockCardSecondStepHook;
