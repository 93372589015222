// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlLocationsToggleComponent from '../toggle/settings-card-control-locations-toggle.component';
// Constants
import constants from './constants/settings-card-control-locations-main.constants';
import countriesConstants from './constants/settings-card-control-locations-main-countries.constants';
// Hooks
import SettingsCardControlLocationsMainHook from './hooks/settings-card-control-locations-main.hook';
// Styles
import {
  SettingsCardControlLocationsMainComponentStyled,
  SettingsCardControlLocationsMainTitleComponentStyled,
} from './settings-card-control-locations-main.component.styled';
// Translations
import translations from './translations/settings-card-control-locations-main.translations';
import { COUNTRIES_TRANSLATION_KEY_PREFFIX } from '../../translations/settings-card-control-operations.translations';
// Utils
import { formatLocationTranslationId } from '../../utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsMainComponent = (): React.ReactElement => {
  const {
    mainCountryId,
    mainCountryName2Dig,
    enableMainCountry,
    formatMessage,
  } = SettingsCardControlLocationsMainHook();
  return (
    <SettingsCardControlLocationsMainComponentStyled>
      <AssetComponent
        {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAIN_ASSET_PROPS}
        id={countriesConstants[mainCountryId]}
      />
      <SettingsCardControlLocationsMainTitleComponentStyled>
        <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAIN_TITLE_PROPS}>
          <FormattedMessageComponent
            id={translations.SWITCH_ON}
            values={{
              country: formatMessage({
                id: formatLocationTranslationId({
                  preffix: COUNTRIES_TRANSLATION_KEY_PREFFIX,
                  location: mainCountryName2Dig,
                }),
              }),
            }}
          />
        </ParagraphComponent>
        <SettingsCardControlLocationsToggleComponent {...{ mainCountryId, enableMainCountry }} />
      </SettingsCardControlLocationsMainTitleComponentStyled>
    </SettingsCardControlLocationsMainComponentStyled>
  );
};

export default SettingsCardControlLocationsMainComponent;
