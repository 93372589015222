// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/card-settings-block-card.services.constants';
// Types
import { CardSettingsBlockCardServicesType } from './types/card-settings-block-card.services.type';
import { ChallengeType } from 'types/challenge.type';

const blockCardService = async ({
  cardId,
  params,
  progressId,
}: CardSettingsBlockCardServicesType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    ...constants,
    customHeaders: progressId ? { progressId } : {},
    params,
    pathParams: { cardId },
  });

export { blockCardService };
