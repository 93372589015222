// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { SettingsCardControlContextConsumerHook } from 'containers/card-settings/views/card-control/contexts/settings-card-control.context';
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Event handlers
import SettingCardControlLocationsWarningTrackingHandlers from '../handlers/setting-card-control-locations-warning.tracking.handlers';
import SettingCardControlLocationsWarningHandlers from '../handlers/setting-card-control-locations-warning.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SettingCardControlLocationsWarningHookType } from './types/setting-card-control-locations-warning.hook.type';

const SettingCardControlLocationsWarningHook = (): SettingCardControlLocationsWarningHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { mainCountry, continents } = SettingsCardControlLocationsContextConsumerHook(),
    { setTabSelectedId } = SettingsCardControlContextConsumerHook(),
    { isAtmCashAllowed, isInPersonPurchaseAllowed } = currentCard?.control || {};

  return {
    ...SettingCardControlLocationsWarningHandlers({
      ...AppTrackingHook(SettingCardControlLocationsWarningTrackingHandlers),
      setTabSelectedId,
    }),
    continents,
    isAtmCashAllowed,
    isInPersonPurchaseAllowed,
    mainCountry,
  };
};

export default SettingCardControlLocationsWarningHook;
