// Services
import { blockCardService } from '../services/card-settings-block-card.services';
// Types
import { CardSettingsBlockCardResolversType } from './types/card-settings-block-card.resolvers.type';
import { ChallengeType } from 'types/challenge.type';
import { ErrorBodyType } from 'types/error-body.type';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const blockCardResolver = async (
  props: CardSettingsBlockCardResolversType
): Promise<[null] | [ChallengeType | ErrorBodyType | null, number]> => {
  const [response, { status }] = await blockCardService(props);

  return requestRespondedNoContent(status) ? [null] : [response, status];
};

export { blockCardResolver };
