const DOMAIN_BLOCK_CARD = 'cobranded.services.userSettings.privateArea.blockCard.';
const DOMAIN_BLOCK_CARD_ERROR =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.error.';
const DOMAIN_BLOCK_CARD_SUCCESS_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.success.buttons.';
const DOMAIN_BLOCK_CARD_SUCCESS_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.success.confirmation.';
const DOMAIN_BLOCK_CARD_FAILED_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.failed.buttons.';
const DOMAIN_BLOCK_CARD_FAILED_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.failed.confirmation.';
const DOMAIN_BLOCK_CARD_STEPS_FIRST =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.';
const DOMAIN_BLOCK_CARD_STEPS_SECOND =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.second.';

const TITLE = `${DOMAIN_BLOCK_CARD}title`;

const ERROR = {
  ERROR_TITLE: `${DOMAIN_BLOCK_CARD_ERROR}title`,
  ERROR_DESCRIPTION: `${DOMAIN_BLOCK_CARD_ERROR}description`,
};

const SUCCESS = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_BLOCK_CARD_SUCCESS_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_BLOCK_CARD_SUCCESS_CONFIRMATION}content`,
  },
  BACK_BUTTTON: `${DOMAIN_BLOCK_CARD_SUCCESS_BUTTONS}back`,
};

const FAILED = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_BLOCK_CARD_FAILED_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_BLOCK_CARD_FAILED_CONFIRMATION}content`,
  },
  CANCEL_BUTTTON: `${DOMAIN_BLOCK_CARD_FAILED_BUTTONS}cancel`,
  TRY_AGAIN_BUTTTON: `${DOMAIN_BLOCK_CARD_FAILED_BUTTONS}again`,
};

const STEPS = {
  FIRST: {
    TITLE: `${DOMAIN_BLOCK_CARD_STEPS_FIRST}title`,
    CANCEL: `${DOMAIN_BLOCK_CARD_STEPS_FIRST}cancel`,
    NEXT: `${DOMAIN_BLOCK_CARD_STEPS_FIRST}next`,
  },
  SECOND: {
    TITLE: `${DOMAIN_BLOCK_CARD_STEPS_SECOND}title`,
    BACK: `${DOMAIN_BLOCK_CARD_STEPS_SECOND}back`,
    CANCEL: `${DOMAIN_BLOCK_CARD_STEPS_SECOND}cancel`,
    NEXT: `${DOMAIN_BLOCK_CARD_STEPS_SECOND}next`,
  },
};

export { TITLE, ERROR, SUCCESS, FAILED, STEPS };
