// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_STEP_FOOTER_PROPS } from './constants/card-settings-block-card-second-step-actions.constants';
// Hooks
import CardSettingsBlockCardSecondStepActionsHook from './hooks/card-settings-block-card-second-step-actions.hook';
// Translations
import { STEPS } from 'containers/card-settings/views/block-card/translations/card-settings-block-card.translations';
// Types
import { CardSettingsBlockCardSecondStepActionsType } from './types/card-settings-block-card-second-step-actions.type';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';

const CardSettingsBlockCardSecondStepActionsComponent = ({
  challenge,
  challengeError,
  handleToggleChallengeError,
}: CardSettingsBlockCardSecondStepActionsType): React.ReactElement => {
  const { handleNextButtonClick } = CardSettingsBlockCardSecondStepActionsHook({
    handleToggleChallengeError,
    challenge,
  });

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={WIZARD_STEP_FOOTER_PROPS.hasDefaultLeftButton}
      rightButtonConfiguration={{
        assetConfiguration: WIZARD_STEP_FOOTER_PROPS.assetConfiguration,
        children: <FormattedMessageComponent id={STEPS.SECOND.NEXT} />,
        disabled: !challenge || challengeError,
        onClick: handleNextButtonClick,
      }}
    />
  );
};

export default CardSettingsBlockCardSecondStepActionsComponent;
