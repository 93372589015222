// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { CardSettingsBlockCardReplacementModalHandlersType } from './types/card-settings-block-card-replacement-modal.handlers.type';
import { CardSettingsBlockCardReplacementModalReturnHandlersType } from './types/card-settings-block-card-replacement-modal-return.handlers.type';
import { CloseButtonClickHandlerType } from './types/close-button-click-handler.type';
import { BackButtonClickHandlerType } from './types/back-button-click-handler.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { isCC } from 'utils/app.utils';

const backButtonClickHandler = ({
  handleBackButtonClickTracking,
  navigate,
  onCancelHybridFlow,
  setShowReplacementModal,
}: BackButtonClickHandlerType) => {
  handleBackButtonClickTracking();
  setShowReplacementModal(false);
  const url = generateAbsoluteRoute(RoutesEnumeration.CARD_SETTINGS);
  isCC() ? navigate(url) : onCancelHybridFlow(url);
};

const closeButtonClickHandler = ({
  handleCloseButtonClickTracking,
  setShowReplacementModal,
}: CloseButtonClickHandlerType) => {
  handleCloseButtonClickTracking();
  setShowReplacementModal(false);
};

const CardSettingsBlockCardReplacementModalHandlers = ({
  handleBackButtonClickTracking,
  handleCloseButtonClickTracking,
  navigate,
  onCancelHybridFlow,
  setShowReplacementModal,
}: CardSettingsBlockCardReplacementModalHandlersType): CardSettingsBlockCardReplacementModalReturnHandlersType => ({
  handleBackButtonClick: () =>
    backButtonClickHandler({
      handleBackButtonClickTracking,
      navigate,
      onCancelHybridFlow,
      setShowReplacementModal,
    }),
  handleCloseButtonClick: () =>
    closeButtonClickHandler({ handleCloseButtonClickTracking, setShowReplacementModal }),
});

export default CardSettingsBlockCardReplacementModalHandlers;
