export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  THIRTY_TWO: 32,
  THIRTY_EIGHT: 38,
  EIGHTY_EIGHT: 88,
  ONE_HUNDRED_SIX: 106,
  ONE_HUNDRED_FORTY_EIGHT: 148,
  SIX_HUNDRED: 600,
};
