// Contexts
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Types
import { CardSettingsBlockCardFirstStepActionsHookReturnType } from './types/card-settings-block-card-first-step-actions.hook.type';

const CardSettingsBlockCardFirstStepActionsHook =
  (): CardSettingsBlockCardFirstStepActionsHookReturnType => {
    const { blockReason } = BlockCardContextConsumerHook();

    return {
      blockReason,
    };
  };

export default CardSettingsBlockCardFirstStepActionsHook;
