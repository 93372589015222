// Constants
import constants from './constants/card-settings-block-card-second-step-summary.configurations.constants';
import serviceConstants from 'containers/card-settings/views/block-card/services/constants/card-settings-block-card.services.constants';
// Types
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';

export const getChallengeConfiguration = ({
  address,
  cardId,
  challengeError,
  handleChallengeError,
  handleChallengeSuccess,
  handleToggleChallengeError,
  params,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: serviceConstants.endpoint,
  baseServiceBuilder: { pathParams: { cardId }, params: { ...params, address } },
  baseServiceMethod: constants.CHALLENGE_PROPS.baseServiceMethod,
  challengeError,
  onError: handleChallengeError,
  onRetry: handleToggleChallengeError,
  onSuccess: handleChallengeSuccess,
});
