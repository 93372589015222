// Vendors
import React from 'react';
// Components
import { MapSelectorComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlLocationsToggleComponent from '../../../toggle/settings-card-control-locations-toggle.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from '../constants/settings-card-control-locations-countries.constants';
// Translations
import { COUNTRIES_TRANSLATION_KEY_PREFFIX } from 'containers/card-settings/views/card-control/components/locations/translations/settings-card-control-operations.translations';
// Types
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { SettingsCardControlLocationsContinentsCountriesComponentType } from './types/settings-card-control-locations-countries.type';
// Styles
import { SettingsCardControlLocationsCountriesComponentStyled } from './settings-card-control-locations-countries.component.styled';
// Utils
import { formatLocationTranslationId } from 'containers/card-settings/views/card-control/components/locations/utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsCountriesComponent = ({
  filteredCountries,
}: SettingsCardControlLocationsContinentsCountriesComponentType): React.ReactElement => (
  <SettingsCardControlLocationsCountriesComponentStyled>
    {filteredCountries?.map(
      ({ filteredCountries: fil, code }: CardControlContinentComponentType, i: number) =>
        fil?.map(({ id: countryId, name2Dig }: CardControlContinentCountryComponentType) => (
          <MapSelectorComponent
            key={code + i}
            title={
              <FormattedMessageComponent
                id={formatLocationTranslationId({
                  preffix: COUNTRIES_TRANSLATION_KEY_PREFFIX,
                  location: name2Dig,
                })}
              />
            }
            {...constants.MAP_SELECTOR_PROPS}
            toggle={<SettingsCardControlLocationsToggleComponent {...{ countryId }} />}
          />
        ))
    )}
  </SettingsCardControlLocationsCountriesComponentStyled>
);

export default SettingsCardControlLocationsCountriesComponent;
