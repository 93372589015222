// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import CardSettingsBlockCardSidebarComponent from './components/sidebar/card-settings-block-card-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import CardSettingsBlockCardReplacementModalComponent from './components/replacement-modal/card-settings-block-card-replacement-modal.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import { BLOCK_CARD_WIZARD_PROPS } from './constants/card-settings-block-card.constants';
// Contexts
import { BlockCardContextConsumerHOC } from 'components/block-card/contexts/block-card.context';
// Hooks
import CardSettingsBlockCardHook from './hooks/card-settings-block-card.hook';
// Translations
import { ERROR } from './translations/card-settings-block-card.translations';

const CardSettingsBlockCardComponent = (): React.ReactElement => {
  const { error, formatMessage, replacementModalActions, showReplacementModal } =
    CardSettingsBlockCardHook();

  return (
    <FetchErrorComponent
      error={getFetchErrorConfiguration({
        error,
        formatMessage,
        translations: ERROR,
      })}
    >
      <OperativeLayoutComponent sidebarComponent={CardSettingsBlockCardSidebarComponent}>
        <WizardComponent {...BLOCK_CARD_WIZARD_PROPS} />
      </OperativeLayoutComponent>
      <CardSettingsBlockCardReplacementModalComponent
        isOpen={showReplacementModal}
        actions={replacementModalActions}
      />
    </FetchErrorComponent>
  );
};

export { CardSettingsBlockCardComponent };

export default BlockCardContextConsumerHOC(CardSettingsBlockCardComponent);
